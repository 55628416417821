<template>
  <div class="page-content">
    <div class="card row">
      <whatsapp v-if="activeChannel.type === 'whatsapp'"/>
      <whatsapp_personal v-if="activeChannel.type === 'whatsapp_personal'"/>
    </div>
  </div>
</template>
<script>
import whatsapp from '@/ui/components/create-template/Whatsapp.vue';
import whatsapp_personal from '@/ui/components/create-template/WhatsappPersonal.vue';

export default {
  name: 'CreateTemplate',
  metaInfo: {
    title: 'Create Template',
  },
  components: {
    whatsapp,
    whatsapp_personal,
  },
  computed: {
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
  },
};
</script>
